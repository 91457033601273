import * as React from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';
import { Link, useTranslation} from 'gatsby-plugin-react-i18next';

// Components
import Layout from '../../components/layout/Layout';
import AuthorCard from '../../components/card/Author';
import PaginationBlock from '../../components/block/Pagination';

// Authors
const AuthorsPage = ({ pageContext, data }) => {
  
  const {t} = useTranslation();
  const { totalCount } = data.authors;

  const pageSchemas = [
    { 
      type: "breadcrumb",
      listItems: [
        { 
          name: t('breadcrumb.authors'),
        }
      ]
    }
  ];

  return (
    <Layout 
      pageType="classic" 
      pageTitle={t('meta.title')} 
      pageDescription={t('meta.description', { title: data.site.siteMetadata.title })} 
      pageKeywords={t('meta.keywords', { title: data.site.siteMetadata.title })} 
      pageSchemas={pageSchemas}
      headerClass="navbar-absolute-top navbar-light navbar-show-hide" headerStyle="light"
    >
      <div className="container content-space-t-3 content-space-t-lg-3 content-space-b-1 content-space-b-md-2">
        <nav aria-label="breadcrumb">
          <ol className="breadcrumb">
            <li className="breadcrumb-item"><Link to={`/`}>{t('breadcrumb.home')}</Link></li>
            <li className="breadcrumb-item active">{t('breadcrumb.authors')}</li>
          </ol>
        </nav>
        <div className="w-md-75 w-lg-50 text-center mx-md-auto">
          <h1 className="display-5">{t('title', { title: data.site.siteMetadata.title, total_count: totalCount })}</h1>
          <p className="lead">{t('subtitle', { title: data.site.siteMetadata.title, total_count: totalCount })}</p>
        </div>
      </div>
      <div className="container content-space-b-2 content-space-b-lg-3">
        <div>
          <div className="row gx-3 mb-7">
            {data.authors.nodes &&
              data.authors.nodes.map(author => (
                <AuthorCard key={author.id} data={author} cardStyle="3" cardClassName="col-sm-6 col-lg-4 mb-4"/>
              ))
            }
          </div>              
          <div>
            <PaginationBlock 
              path={`authors`} 
              currentPage={pageContext.currentPage} 
              numPages={pageContext.numPages} 
            />
          </div>
        </div>
      </div>
    </Layout>
  )
}

AuthorsPage.propTypes = {
  data: PropTypes.shape({
  }),
}

export const query = graphql`
  query ($language: String!, $skip: Int!, $limit: Int!) {
    site: site {
      siteMetadata {
        siteUrl
        title
      }
    }
    authors: allMdx(
      filter: {
        fileAbsolutePath: { regex: "/data/authors/" },
        frontmatter: { active: { eq: true }, language: {eq: $language} }
      },
      sort: {fields: frontmatter___date_published, order: DESC}
      limit: $limit
      skip: $skip
    ) {
      nodes {
        frontmatter {
          meta {
            title
            description
            keywords
          }
          name
          description
          position
          languages
          social_networks {
            linkedIn
            facebook
            instagram
            twitter
          }
          image {
            profile {
              childImageSharp {
                gatsbyImageData
              }
            }
            card {
              childImageSharp {
                gatsbyImageData(width: 800)
              }
            }
            alt
          }
          home
          active
          language
          path
          date(formatString: "D MMMM YYYY")
        }
        id
      }
      totalCount
    }
    locales: allLocale(filter: {ns: {in: ["common", "header", "breadcrumb", "authors", "categories", "tags", "pagination", "footer"]}, language: {eq: $language}}) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`

export default AuthorsPage